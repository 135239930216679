import React from "react"
import Marquee from "react-fast-marquee"

const Ticker = () => {
	return (
		<Marquee className="ticker" gradient={false} pauseOnHover={true} speed={70}>
			<div className="ticker__div">
				Sign up for JSPF! Fill our <a data-testid="interest-form" className="page__link" href="https://docs.google.com/forms/d/e/1FAIpQLSeZCJx4Dmj2TjDA9odS_B3o5k9oNjH1zln9O2ak3EW1kvLTpA/viewform" target="_blank" rel="noreferrer">Interest form </a>today.
			</div>
			<div className="ticker__div">
				Our Annual report for 2022-2024 is now out! Read it <a data-testid="annual-report" className="page__link" href="https://drive.google.com/file/d/1IxoICrPmRi_EoeQGdbQXWcxKewXeAldJ/view?usp=drivesdk" target="_blank" rel="noreferrer">here</a>.
			</div>
		</Marquee>
	)
}

export default Ticker
